import axios from "axios";



function addCompare(state, payload) {
  let product = payload.product
  let $root = payload.$root
  let max = window.innerWidth < 768 ? 3 : 4
  if (state.compares.length >= max) {
    $root.notify(`حداکثر انتخاب ${max} مورد امکان پذیر است`, 'warning')
    return
  }
  state.compares.push(product)
}

function removeCompare(state, payload) {
  let product = payload.product

  state.compares = state.compares.filter(i => i.id != product.id)
}



const state = {
  compares: []
};

const getters = {
  getCompares(state) {
    return state.compares
  }
};
const mutations = {
  resetCompare(state) {
    state.compares = []
  },
  toggleCompare(state, payload) {
    let product = payload.product
    let $root = payload.$root
    let exists = state.compares.find(i => i.id == product.id);
    if (exists) {
      removeCompare(state, { product, $root })
    } else {
      addCompare(state, { product, $root })
    }
  },
  addCompare(state, payload) {
    addCompare(state, payload)
  },
  removeCompare(state, payload) {
    removeCompare(state, payload)
  },
};
const actions = {

};

export default {
  state,
  getters,
  mutations,
  actions
}
